import type { CSSProperties, ReactElement } from 'react'
import { Button, Col, message, Select } from 'antd'
import { useDebounce } from '../../hooks/useDebounce'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from './slice'
import { useAppSelector } from '../../hooks/useAppSelector'
import LoadingSelector from '../../components/LoadingSelector'
import { myCompaniesSelector } from './selectors.ts'
import { getCompanySlug } from '../../utils/helpers.ts'
import { Link } from 'react-router-dom'
import { CopyOutlined, LinkOutlined } from '@ant-design/icons'
import copy from 'copy-text-to-clipboard'

interface Props {
    initialValue?: string
    onChange: (value: string) => void
    placeholder?: string
    style?: CSSProperties
    dataKey?: string
}

export default function MyCompaniesSelector({
    initialValue,
    onChange,
    placeholder,
    style,
    dataKey = 'value'
}: Props): ReactElement {
    const [hasInitialize, setHasInitialize] = useState(false)
    const [preData, setPreData] = useState<{ label: string; value: string }[]>(
        []
    )
    const [search, setSearch] = useState('')
    const debounceSearch = useDebounce<string>(search, 600)
    const [messageApi, contextHolder] = message.useMessage()

    const dispatch = useDispatch()

    const { data, isFetching } = useAppSelector(myCompaniesSelector)

    useEffect(() => {
        if (!hasInitialize && initialValue) {
            if (initialValue.includes('{d}')) {
                const [label, value] = initialValue.split('{d}')
                setPreData([{ label, value }])
            }
            dispatch(actions.fetch({ search: '' }))
        }
    }, [initialValue])

    useEffect(() => {
        if (debounceSearch) {
            dispatch(actions.fetch({ search: debounceSearch }))
            setHasInitialize(true)
        } else {
            dispatch(actions.fetch({ search: '' }))
        }
    }, [debounceSearch])

    const handleCopyUrl = () => {
        if (initialValue) {
            const url = initialValue.split('{d}')
            if (url[0]) {
                copy(url[0])
                void messageApi.open({
                    type: 'info',
                    content: 'Ссылка на компанию была скопирована!'
                })
            }
        }
    }

    return (
        <div
            style={{
                ...style,
                display: 'flex',
                alignItems: 'center',
                position: 'relative'
            }}
        >
            <Select
                showSearch
                allowClear
                style={{ width: '100%' }}
                value={!hasInitialize ? preData[0]?.label : undefined}
                options={data.map(item => ({
                    label: `${process.env.SITE_URL}/${getCompanySlug(item.slug, item.short_id, item.company_id)}`,
                    value: `${process.env.SITE_URL}/${item.dadata_area ? (item.dadata_area?.short_slug ?? item.dadata_area.slug) : 'rossiya'}/${item.slug_title}`,
                    company_id: item.company_id,
                    desc: item.name
                }))}
                optionRender={option => (
                    <Col>
                        {option.data.desc}
                        <br />
                        <div
                            style={{
                                marginTop: -2,
                                color: '#faad14',
                                fontSize: 12
                            }}
                        >
                            {option.data.value}
                        </div>
                        <Link to={option.data.value} target={'_blank'}>
                            <Button icon={<LinkOutlined />} size={'small'}>
                                {'Перейти в компанию'}
                            </Button>
                        </Link>
                    </Col>
                )}
                filterOption={false}
                notFoundContent={isFetching ? <LoadingSelector /> : null}
                loading={isFetching}
                placeholder={
                    placeholder ??
                    'Введите название созданной компании на Mamado'
                }
                searchValue={search}
                onSearch={setSearch}
                onClear={() => setHasInitialize(true)}
                onChange={(value, options) => {
                    if (options && !Array.isArray(options)) {
                        onChange(
                            `${options.label}{d}${options[dataKey as keyof typeof options]}`
                        )
                    } else {
                        onChange(value)
                    }
                }}
            />
            {initialValue ? (
                <Button
                    style={{ position: 'absolute', right: 35 }}
                    size={'small'}
                    icon={<CopyOutlined />}
                    onClick={handleCopyUrl}
                />
            ) : null}
            {contextHolder}
        </div>
    )
}
