import type { AxiosResponse } from 'axios'
import { get, patch } from '../../utils/api'
import type { IUser } from './types'

export const fetch = (
    page: number,
    page_size: number,
    filter: Record<string, string>
): Promise<AxiosResponse<IUser[]>> => {
    const filterParam: Record<string, string> = {}

    for (const key in filter) {
        filterParam[`filter[${key}]`] = filter[key].includes('{d}')
            ? filter[key].split('{d}')[1]
            : filter[key]
    }

    return get<IUser[]>('/admin/users/paginate', {
        page,
        page_size,
        include: ['homeAddress', 'balance', 'businessAccount'],
        ...filterParam
    })
}

export const downloadExcel = async (
    page: number,
    page_size: number,
    filter: Record<string, string>
): Promise<void> => {
    const filterParam: Record<string, string> = {}

    for (const key in filter) {
        filterParam[`filter[${key}]`] = filter[key].includes('{d}')
            ? filter[key].split('{d}')[1]
            : filter[key]
    }

    const response = await get(
        '/admin/users/export',
        {
            page,
            page_size,
            include: 'homeAddress',
            ...filterParam
        },
        {
            responseType: 'blob'
        }
    )

    const timestamp = new Date().getTime()
    const fileName = `users-${timestamp}.xlsx`

    const url = window.URL.createObjectURL(
        new Blob([response.data as BlobPart])
    )
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)

    document.body.appendChild(link)
    link.click()

    link.parentNode?.removeChild(link)
}

export const editUser = (
    userId: string,
    form: Record<string, unknown>
): Promise<AxiosResponse<void>> => patch<void>(`/admin/users/${userId}`, form)
