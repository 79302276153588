import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type {
    InitialStateType,
    FetchPayload,
    FetchSuccessPayload,
    FetchFailurePayload
} from './types'

const initialState: InitialStateType = {
    data: [],
    isFetching: false,
    error: null
}

const tagsSelectorSlice = createSlice({
    name: 'tagsSelector',
    initialState,
    reducers: {
        fetch(state: InitialStateType, _action: PayloadAction<FetchPayload>) {
            state.isFetching = true
            state.error = null
        },
        fetchSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchSuccessPayload>
        ) {
            state.data = action.payload.data
                .map(category => ({
                    ...category,
                    categoryId: category.category_id,
                    tags: action.payload.dataTags.data.filter(
                        tag =>
                            tag.post_category_id === category.post_category_id
                    )
                }))
                .filter(
                    category =>
                        Array.isArray(category.tags) && category.tags.length > 0
                )
            state.isFetching = false
        },
        fetchFailure(
            state: InitialStateType,
            action: PayloadAction<FetchFailurePayload>
        ) {
            state.isFetching = false
            state.error = action.payload.error
        },
        destroy(
            _state: InitialStateType,
            _action: PayloadAction<undefined>
        ): InitialStateType {
            return initialState
        }
    }
})

export const actions = tagsSelectorSlice.actions

export default tagsSelectorSlice.reducer
