import type { ReactElement } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Typography } from 'antd'
import NextStepButton from '../../../../components/NextStepButton'
import CompanyTagsSelector from '../../../CompanyTagsSelector'
import { useDispatch } from 'react-redux'
import { actions } from '../../slice.ts'
import { useAppSelector } from '../../../../hooks/useAppSelector.ts'
import { companySelector } from '../../selectors.ts'

const { Title } = Typography

export default function TabTags(): ReactElement {
    const [selectedTags, setSelectedTags] = useState<string[]>([])

    const { currentCompany, isSaving } = useAppSelector(companySelector)

    const dispatch = useDispatch()

    useEffect(() => {
        if (currentCompany) {
            setSelectedTags(
                currentCompany.company_tags.map(tag => tag.company_tag_id)
            )
        }
    }, [currentCompany])

    const handleSaveTags = (): void => {
        if (currentCompany) {
            dispatch(
                actions.saveCompany({
                    company_id: currentCompany.company_id,
                    form: {
                        company_tags: selectedTags
                    }
                })
            )
        }
    }

    return (
        <>
            <Title style={{ marginTop: 0 }} level={5}>
                {'Настройка тегов категории'}
            </Title>
            <CompanyTagsSelector
                selectedTags={selectedTags}
                onChange={setSelectedTags}
            />
            <NextStepButton
                hideNext={true}
                onClick={handleSaveTags}
                loading={isSaving}
            >
                {'Сохранить изменения'}
            </NextStepButton>
        </>
    )
}
