import type { ReactElement } from 'react'
import { useEffect } from 'react'
import { Select } from 'antd'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { eventTagsSelector } from './selectors.ts'

interface Props {
    selectedTags: string[]
    onChange: (tags: string[]) => void
}

export default function EventTagsSelector({
    selectedTags,
    onChange
}: Props): ReactElement {
    const dispatch = useDispatch()

    const { tags, isFetching } = useAppSelector(eventTagsSelector)

    useEffect(() => {
        dispatch(actions.fetch())
    }, [])

    return (
        <Select
            value={isFetching ? undefined : selectedTags}
            mode={'multiple'}
            allowClear={true}
            style={{ width: 350 }}
            placeholder={'Выберите теги категории'}
            loading={isFetching}
            disabled={isFetching}
            options={tags.map(tag => ({
                label: tag.title,
                value: tag.event_tag_id
            }))}
            onChange={onChange}
        />
    )
}
