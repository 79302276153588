import { useEffect, useState } from 'react'
import type { ReactElement } from 'react'
import { useForm, useWatch } from 'antd/es/form/Form'
import { useDispatch } from 'react-redux'
import { Typography, Form, Input, Radio, Slider, Row, Modal } from 'antd'
import { actions } from '../../slice'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import NextStepButton from '../../../../components/NextStepButton'
import RadioDescription from '../../../../components/RadioDescription'
import { companyWizardSelector } from '../../selectors'

// Styles
import styles from './styles.module.scss'
import { checkAdminRole } from '../../../../utils/helpers.ts'
import { authorizationSelector } from '../../../Authorization/selectors.ts'
import { WarningOutlined } from '@ant-design/icons'

const { Title, Paragraph } = Typography

export default function BasicStep(): ReactElement {
    const [isShowConfirmNickname, setIsShowConfirmNickname] = useState(false)
    const [isModalConfirm, setIsModalConfirm] = useState(false)

    const dispatch = useDispatch()

    const [form] = useForm()

    const {
        currentCompany,
        hasStepChanges,
        preCreateData,
        isProcessCreate,
        isSaving
    } = useAppSelector(companyWizardSelector)

    const { profile } = useAppSelector(authorizationSelector)

    const isAdmin = checkAdminRole(profile)

    const watchAgeCategory = useWatch('age_category', form)

    useEffect(() => {
        if (currentCompany) {
            const keys = ['name', 'slug', 'age_category', 'age_min', 'age_max']
            const hasChanges = keys.some(key => {
                const eventValue =
                    currentCompany[key as keyof typeof currentCompany] === 100
                        ? 18
                        : currentCompany[key as keyof typeof currentCompany]
                return (
                    preCreateData[key as keyof typeof preCreateData] !==
                    eventValue
                )
            })

            dispatch(actions.setHasStepChanges(hasChanges))
        }
    }, [preCreateData, currentCompany])

    const handleUndoChanges = () => {
        if (currentCompany) {
            const params = {
                name: currentCompany.name,
                slug: currentCompany.slug,
                age_category: currentCompany.age_category,
                age_min:
                    currentCompany.age_category !== 'adult'
                        ? currentCompany.age_min
                        : 0,
                age_max:
                    currentCompany.age_category !== 'adult'
                        ? currentCompany.age_max > 18
                            ? 18
                            : currentCompany.age_max
                        : 18
            }

            dispatch(actions.setParam(params))
            form.setFieldsValue(params)
        }
    }

    useEffect(() => {
        handleUndoChanges()
    }, [currentCompany])

    const isChildrenSelected = preCreateData.age_category === 'children'

    const handleChangeAgeCategoryMultiple = (value: number[]) => {
        dispatch(actions.setParam({ age_min: value[0], age_max: value[1] }))
    }

    const handleChangeAgeCategory = (value: number) => {
        dispatch(actions.setParam({ age_min: value }))
    }

    const handleSendBasic = (isTrusted: boolean) => {
        if (currentCompany) {
            dispatch(
                actions.saveCompany({
                    company_id: currentCompany.company_id,
                    form: {
                        name: preCreateData.name,
                        slug:
                            preCreateData.slug === currentCompany.slug
                                ? undefined
                                : preCreateData.slug,
                        age_category: preCreateData.age_category,
                        age_min: preCreateData.age_min,
                        age_max: preCreateData.age_max
                    },
                    isBackSettings: !isTrusted
                })
            )
        } else {
            dispatch(actions.createCompany())
        }
    }

    return (
        <>
            <Form
                form={form}
                layout={'vertical'}
                initialValues={{
                    name: preCreateData.name,
                    age_category: preCreateData.age_category
                }}
                onValuesChange={values => {
                    if (values.slug === '') {
                        values.slug = null
                    }

                    dispatch(actions.setParam(values))
                }}
            >
                <Form.Item
                    label={'Название компании (заголовок на лендинге)'}
                    name={'name'}
                >
                    <Input allowClear placeholder={'Название компании'} />
                </Form.Item>
                {!currentCompany || isAdmin ? (
                    <>
                        <Form.Item
                            label={
                                'Никнейм (для формирования ссылки на ваш лендинг)'
                            }
                            name={'slug'}
                            tooltip={
                                'Никнейм - название web страницы для создаваемой компании. Например, никнейм: ponchik. Web страница компании в этом случае: https://mamado.su/[город]/ponchik-id[номер]. Использовать для никнейм только латинские буквы и цифры.'
                            }
                            rules={[
                                {
                                    min: 3,
                                    message:
                                        'Никнейм должен состоять из минимум 3 символа.'
                                }
                            ]}
                            normalize={value =>
                                value
                                    .replace(/[^a-zA-Z0-9-]+/g, '-')
                                    .replace(/--+/g, '-')
                                    .replace(/^-+/g, '')
                                    .toLowerCase()
                            }
                        >
                            <Input
                                allowClear
                                placeholder={'Никнейм'}
                                maxLength={
                                    currentCompany?.slug &&
                                    currentCompany.slug.length > 80
                                        ? currentCompany.slug.length
                                        : 80
                                }
                                onClick={() => {
                                    if (
                                        currentCompany &&
                                        currentCompany?.status !== 'draft' &&
                                        !isShowConfirmNickname &&
                                        isAdmin
                                    ) {
                                        setIsModalConfirm(true)
                                        setIsShowConfirmNickname(true)
                                    }
                                }}
                                onBlur={() => {
                                    const value = form.getFieldValue('slug')
                                    if (typeof value === 'string') {
                                        const formatValue = value.replace(
                                            /-$/,
                                            ''
                                        )
                                        form.setFieldValue('slug', formatValue)
                                        dispatch(
                                            actions.setParam({
                                                slug: formatValue
                                            })
                                        )
                                    }
                                }}
                            />
                        </Form.Item>
                        {form.getFieldValue('slug') ? (
                            <>
                                <Paragraph
                                    style={{
                                        marginTop: -20,
                                        marginBottom: 0,
                                        fontSize: 12
                                    }}
                                    type={'warning'}
                                    copyable={true}
                                >{`${process.env.SITE_URL}/${currentCompany?.dadata_area ? (currentCompany.dadata_area.short_slug ?? currentCompany.dadata_area.slug) : currentCompany ? 'rossiya' : '[город]'}/${form.getFieldValue(
                                    'slug'
                                )}-id${currentCompany ? currentCompany?.short_id : '[номер]'}`}</Paragraph>
                                {currentCompany && !isAdmin ? (
                                    <Paragraph
                                        style={{ marginTop: 0, fontSize: 12 }}
                                        type={'secondary'}
                                    >
                                        {
                                            'Ссылка на ваш лендинг станет активной после публикации компании.'
                                        }
                                        <br />
                                        {
                                            'Редактирование доступно только при создании.'
                                        }
                                    </Paragraph>
                                ) : !isAdmin ? (
                                    <Paragraph
                                        style={{ marginTop: 0, fontSize: 12 }}
                                        type={'secondary'}
                                    >
                                        {
                                            'Редактирование доступно только по запросу через “Службу заботы Mamado”.'
                                        }
                                    </Paragraph>
                                ) : (
                                    <div style={{ height: 20 }} />
                                )}
                            </>
                        ) : null}
                    </>
                ) : currentCompany?.slug_title ? (
                    <Form.Item
                        label={
                            'Никнейм (для формирования ссылки на ваш лендинг)'
                        }
                        tooltip={
                            'Никнейм - название web страницы для создаваемой компании. Например, никнейм: ponchik. Web страница компании в этом случае: https://mamado.su/[город]/ponchik-id[номер]. Использовать для никнейм только латинские буквы и цифры.'
                        }
                        style={{ marginTop: 0, marginBottom: 10 }}
                    >
                        <Paragraph
                            style={{
                                margin: 0,
                                fontSize: 12
                            }}
                            type={'warning'}
                            copyable={true}
                        >{`${process.env.SITE_URL}/${currentCompany?.dadata_area ? (currentCompany.dadata_area.short_slug ?? currentCompany.dadata_area.slug) : 'rossiya'}/${currentCompany.slug_title}`}</Paragraph>
                        <Paragraph
                            style={{ marginTop: 0, fontSize: 12 }}
                            type={'secondary'}
                        >
                            {
                                'Редактирование доступно только по запросу через «Службу заботы Mamado»'
                            }
                        </Paragraph>
                    </Form.Item>
                ) : null}
                <div className={styles.children}>
                    <Title style={{ margin: 0 }} level={5}>
                        {'Место или услуги подходит детям?'}
                    </Title>
                    <RadioDescription>
                        {
                            'Насколько ваша основная деятельность подходит для детей и посещения с детьми'
                        }
                    </RadioDescription>
                    <Form.Item name={'age_category'}>
                        <Radio.Group
                            style={{ marginTop: 15 }}
                            buttonStyle={'solid'}
                        >
                            <Radio.Button value={'adult'}>
                                {'Не подходит детям'}
                            </Radio.Button>
                            <Radio.Button value={'all'}>
                                {'Удобно взрослым и детям'}
                            </Radio.Button>
                            <Radio.Button value={'children'}>
                                {'Услуги только для детей'}
                            </Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </div>
                {preCreateData.age_category !== 'adult' ? (
                    <Form.Item label={'Ограничение по возрасту ребенка'}>
                        {isChildrenSelected ? (
                            <Slider
                                range
                                value={[
                                    preCreateData.age_min ?? 0,
                                    preCreateData.age_max ?? 18
                                ]}
                                onChange={handleChangeAgeCategoryMultiple}
                                style={{ margin: '0 5px 10px' }}
                                min={0}
                                max={18}
                            />
                        ) : (
                            <Slider
                                value={preCreateData.age_min}
                                onChange={handleChangeAgeCategory}
                                style={{ margin: '0 5px 10px' }}
                                reverse={watchAgeCategory === 'all'}
                                tooltip={{
                                    formatter() {
                                        return (
                                            (preCreateData.age_max ?? 18) -
                                            (preCreateData.age_min ?? 0)
                                        )
                                    }
                                }}
                                min={0}
                                max={18}
                            />
                        )}
                        {watchAgeCategory === 'all' ? (
                            <Row justify={'space-between'}>
                                <span className={styles.age}>{`от ${
                                    (preCreateData.age_max ?? 18) -
                                    (preCreateData.age_min ?? 0)
                                } лет`}</span>
                                <span className={styles.age}>
                                    {'до 18 лет'}
                                </span>
                            </Row>
                        ) : (
                            <Row justify={'space-between'}>
                                <span
                                    className={styles.age}
                                >{`от ${preCreateData.age_min} лет`}</span>
                                <span className={styles.age}>{`до ${
                                    isChildrenSelected
                                        ? preCreateData.age_max
                                        : 18
                                } лет`}</span>
                            </Row>
                        )}
                    </Form.Item>
                ) : null}
            </Form>
            <NextStepButton
                loading={isProcessCreate || isSaving}
                disabled={
                    preCreateData.name!.length < 3 ||
                    (!!currentCompany && !hasStepChanges)
                }
                onClick={handleSendBasic}
                showReturnChangesButton={!!currentCompany && hasStepChanges}
                onReturnChanges={handleUndoChanges}
            >
                {currentCompany ? 'Сохранить' : 'Создать компанию'}
            </NextStepButton>
            <Modal
                open={isModalConfirm}
                title={
                    <>
                        <WarningOutlined
                            style={{ color: 'red', marginRight: 5 }}
                        />{' '}
                        {'Внимание'}
                    </>
                }
                cancelButtonProps={{ style: { display: 'none' } }}
                okText={'Понятно'}
                onOk={() => setIsModalConfirm(false)}
            >
                {'Менять никнейм в опубликованной компании не желательно!'}
            </Modal>
        </>
    )
}
