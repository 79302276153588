import type { ReactElement } from 'react'
import type React from 'react'
import { useState } from 'react'
import { CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Flex, Popconfirm, Tag, theme, Tooltip } from 'antd'
import type { ITag } from '../../containers/TagsSelector/types.ts'
import TagWizardModal from '../TagWizardModal'
import { useDispatch } from 'react-redux'
import { actions } from '../../containers/SettingsTags/slice.ts'

interface Props {
    selectedTags: ITag[]
    color: string
    postCategoryId: string
}

export default function TagsWizard({
    selectedTags,
    color,
    postCategoryId
}: Props): ReactElement {
    const { token } = theme.useToken()

    const [selectedTag, setSelectedTag] = useState<ITag | undefined>()

    const dispatch = useDispatch()

    const tagPlusStyle: React.CSSProperties = {
        height: 22,
        background: token.colorBgContainer,
        borderStyle: 'dashed',
        cursor: 'pointer'
    }

    return (
        <>
            <Flex gap={'4px 0'} wrap={'wrap'}>
                {selectedTags.map<React.ReactNode>(tag => {
                    const isLongTag = tag.title.length > 20
                    const tagElem = (
                        <Tag
                            key={tag.post_tag_id}
                            style={{ userSelect: 'none' }}
                            color={color}
                        >
                            {isLongTag
                                ? `${tag.title.slice(0, 20)}...`
                                : tag.title}
                            <EditOutlined
                                onClick={() => {
                                    const findTag = selectedTags.find(
                                        tagItem =>
                                            tagItem.post_tag_id ===
                                            tag.post_tag_id
                                    )
                                    setSelectedTag(findTag)
                                    dispatch(actions.toggleTagWizardModal(true))
                                }}
                                style={{
                                    marginLeft: 5,
                                    cursor: 'pointer',
                                    outline: 0
                                }}
                            />
                            <Popconfirm
                                placement={'topLeft'}
                                title={'Вы уверены, что хотите удалить тег?'}
                                description={
                                    'Важна последовательность тегов, если удалить, некоторые страницы будут отдавать 404'
                                }
                                okText={'Да'}
                                cancelText={'Нет'}
                                onConfirm={() =>
                                    dispatch(
                                        actions.removeTag({
                                            post_tag_id: tag.post_tag_id
                                        })
                                    )
                                }
                            >
                                <CloseOutlined style={{ color: '#FFF' }} />
                            </Popconfirm>
                        </Tag>
                    )
                    return isLongTag ? (
                        <Tooltip title={tag.title} key={tag.post_tag_id}>
                            {tagElem}
                        </Tooltip>
                    ) : (
                        tagElem
                    )
                })}
                <Tag
                    style={tagPlusStyle}
                    icon={<PlusOutlined />}
                    onClick={() => dispatch(actions.toggleTagWizardModal(true))}
                >
                    {'Новый тег'}
                </Tag>
            </Flex>
            <TagWizardModal
                selectedTag={selectedTag}
                postCategoryId={postCategoryId}
                onAfterClose={() => {
                    setSelectedTag(undefined)
                }}
            />
        </>
    )
}
