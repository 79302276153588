import type { LegacyRef } from 'react'
import {
    useState,
    type ReactElement,
    useMemo,
    useEffect,
    useRef,
    memo
} from 'react'
import type { MenuProps } from 'antd'
import {
    Button,
    Layout as AntdLayout,
    theme,
    Menu,
    Breadcrumb,
    Avatar,
    Typography,
    Modal,
    Badge,
    Spin,
    Dropdown
} from 'antd'
import {
    CompassOutlined,
    UserOutlined,
    LogoutOutlined,
    HomeOutlined,
    BarcodeOutlined,
    SoundOutlined,
    RiseOutlined,
    SettingOutlined,
    PlusOutlined,
    PieChartOutlined
    // DollarOutlined
    // BarcodeOutlined
} from '@ant-design/icons'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import type { MenuItem } from '../../utils/helpers'
import { adminFranchiseeHorizontalBlocks } from '../../utils/helpers'
import {
    additionalBlocks,
    adminFranchiseeBlocks,
    getRole,
    getRoleName,
    horizontalBlocks,
    mapMenuItem,
    pagesWithoutContainer,
    userBlocks,
    verticalBlocks
} from '../../utils/helpers'
import { useAppSelector } from '../../hooks/useAppSelector'
import { useDispatch } from 'react-redux'
import { actions } from '../../containers/Authorization/slice'
import { actions as dashboardActions } from '../../containers/Dashboard/slice'
import { companyWizardSelector } from '../../containers/CompanyWizard/selectors'
import { companySelector } from '../../containers/Company/selectors'
import { eventWizardSelector } from '../../containers/EventWizard/selectors'
import type { IProfile } from '../../containers/Authorization/types'
import { authorizationSelector } from '../../containers/Authorization/selectors'
import { dashboardSelector } from '../../containers/Dashboard/selectors'
import Banner from '../Banner'
import type { IBanner } from '../../containers/Dashboard/types'
import CustomContainer from '../CustomContainer'
import ScrollToTop from '../ScrollToTop'

// Styles
import styles from './styles.module.scss'
import AddBalanceModal from '../AddBalanceModal'

const { Paragraph } = Typography

const { Header, Content, Sider, Footer } = AntdLayout

const items = (profile: IProfile | null): MenuItem[] => {
    if (!profile) {
        return []
    }

    const hasPremium = profile
        ? profile.businessAccount?.planSubscriptions.some(
              item => item.is_active
          )
        : false

    const role = getRole(profile.roles)

    const isAdminOrFranchisee = role === 'admin' || role === 'franchisee'

    const result = [
        mapMenuItem('Главная', 'general', <HomeOutlined />),
        mapMenuItem('Размещение', 'accommodation', <CompassOutlined />, [
            mapMenuItem('О размещении', 'content/admin-about-accommodation'),
            mapMenuItem('Компании', 'companies'),
            mapMenuItem('Предложения', 'events'),
            mapMenuItem(
                'Тарифы',
                'content/admin-tariff-page'
                // <div className={styles.icons__tariff} />
            )
        ])
    ]

    result.push(
        mapMenuItem('Продвижение', 'promotion', <RiseOutlined />, [
            mapMenuItem('О продвижении', 'content/admin-about-promotion'),
            mapMenuItem('Мои статьи', 'blog'),
            mapMenuItem(
                'КроссПромо',
                hasPremium || role === 'franchisee' || role === 'admin'
                    ? 'content/admin-premium-cross-promo-page'
                    : 'content/admin-cross-promo-page'
            ),
            mapMenuItem('Рассылки', 'content/mailings')
        ])
    )

    result.push(
        mapMenuItem('Реклама', 'commercial', <SoundOutlined />, [
            mapMenuItem('О рекламе', 'content/admin-about-advertising'),
            mapMenuItem('Кампании', 'advertising')
        ])
    )

    result.push(
        mapMenuItem('Промокоды', 'promocodes-group', <BarcodeOutlined />, [
            mapMenuItem('О промокодах', 'content/admin-about-promocodes'),
            mapMenuItem('Промокоды', 'promocodes'),
            ...(isAdminOrFranchisee
                ? [mapMenuItem('Мои агенты', 'promocodes/agents')]
                : []),
            mapMenuItem('Отчет', 'promocodes/report')
        ])
    )

    if (role !== 'user') {
        result.push(mapMenuItem('Пользователи', 'users', <UserOutlined />))
    }

    if (role === 'admin') {
        result.push(
            mapMenuItem('Настройки', 'admin-settings', <SettingOutlined />, [
                mapMenuItem('Теги для статьи', 'settings/tags'),
                mapMenuItem('Настройка охватов', 'settings/coverage'),
                mapMenuItem('Присвоение городов', 'settings/assignment'),
                mapMenuItem('Теги компаний', 'settings/companies-tags'),
                mapMenuItem('Теги предложений', 'settings/events-tags')
            ])
        )
    }

    return result
}

type BreadcrumbTitleType = {
    title: string
}

interface IBreadcrumbItem {
    [key: string]:
        | {
              [key: string]: IBreadcrumbItem | BreadcrumbTitleType
          }
        | BreadcrumbTitleType
}

const breadcrumbsItems: IBreadcrumbItem = {
    general: {
        title: 'Главная'
    },
    companies: {
        title: 'Компании',
        create: {
            title: 'Создание компании'
        },
        id: {
            title: 'Карточка компании',
            edit: {
                title: 'Редактирование компании'
            }
        }
    },
    events: {
        title: 'Предложения',
        create: {
            title: 'Создание предложения'
        },
        id: {
            title: 'Карточка предложения',
            edit: {
                title: 'Редактирование предложения'
            }
        }
    },
    users: {
        title: 'Пользователи'
    }
}

export interface Props {
    children: ReactElement
    hideBreadcrumbs?: boolean
}

function MenuBanner({
    banner,
    topOffset,
    refBanner,
    onLoad
}: {
    banner?: IBanner
    topOffset: number
    refBanner: LegacyRef<HTMLDivElement>
    onLoad: () => void
}): ReactElement {
    if (!banner) {
        return <></>
    }

    return (
        <div
            ref={refBanner}
            style={{ top: topOffset, opacity: topOffset > 0 ? 1 : 0 }}
            className={styles.sticky}
        >
            <Banner
                // url={
                //     banner.target_action === 'page'
                //         ? `${document.location.href}content/${banner.page_name}`
                //         : banner.target_url
                // }
                image={banner.image.url}
                onLoad={() => (topOffset === 0 ? onLoad() : null)}
                imageClassName={styles.banner__menu}
            />
        </div>
    )
}

const MemoMenuBanner = memo(
    MenuBanner,
    (prevProps, nextProps) =>
        prevProps.topOffset !== 0 && prevProps.topOffset === nextProps.topOffset
)

export default function Layout({
    children,
    hideBreadcrumbs
}: Props): ReactElement {
    const [collapsed, setCollapsed] = useState(false)
    const [logOutModal, setLogOutModal] = useState(false)
    const [topOffset, setTopOffset] = useState(0)
    const [leftBanner, setLeftBanner] = useState<IBanner | undefined>()
    const [openedMenuKeys, setOpenedMenuKeys] = useState<string[] | undefined>(
        undefined
    )
    const [isModalAddBalance, setIsModalAddBalance] = useState(false)

    const {
        token: { colorBgContainer }
    } = theme.useToken()

    const dispatch = useDispatch()

    const refBanner = useRef<HTMLDivElement>(null)
    const refLastFindSubKey = useRef('')
    const refSlider = useRef<HTMLDivElement>(null)
    const refCurrency = useRef<HTMLDivElement>(null)

    const { profile, amount, isProcessLogOut, isProcessPaymentSum } =
        useAppSelector(authorizationSelector)
    const { currentCompany } = useAppSelector(companyWizardSelector)
    const { currentEvent } = useAppSelector(eventWizardSelector)
    const { currentCompany: currentCompanyPage } =
        useAppSelector(companySelector)
    const { blocks } = useAppSelector(dashboardSelector)

    const location = useLocation()
    const navigate = useNavigate()

    const pathnames = location.pathname.split('/').filter(Boolean)

    const currentItems = items(profile)

    const menuBalanceItems: MenuProps['items'] = useMemo(
        () => [
            {
                key: '1',
                label: 'Пополнить',
                icon: <PlusOutlined />
            },
            {
                key: '3',
                label: <Link to={'/balance'}>{'История операций'}</Link>,
                icon: <PieChartOutlined />
            }
        ],
        []
    )

    useEffect(() => {
        if (profile) {
            const chatraData = {
                name: profile.full_name,
                email: profile.email,
                phone: profile.phone,
                notes: '⚙️ Панель администрирования',
                'user role': getRole(profile.roles),
                'user id': profile.user_id,
                'business account id':
                    profile.businessAccount?.businessAccountId,
                'screen url': window.location.href,
                'account type': 'business'
            }

            ;(function (d, w, c: keyof typeof window) {
                w.ChatraID = process.env.CHATRA_ID
                w.ChatraGroupID = process.env.CHATRA_GROUP_ID
                w.ChatraSetup = {
                    colors: {
                        buttonText: '#f0f0f0',
                        buttonBg: '#552c60'
                    }
                }
                w.ChatraSetup.clientId =
                    profile.businessAccount?.businessAccountId
                w.ChatraIntegration = chatraData
                const s = d.createElement('script')
                // @ts-ignore
                w[c] =
                    w[c] ||
                    function () {
                        ;(w[c].q = w[c].q || []).push(arguments)
                    }
                s.async = true
                s.src = 'https://call.chatra.io/chatra.js'
                if (d.head) d.head.appendChild(s)
            })(document, window, 'Chatra')
        }
    }, [profile])

    const setDefaultOpenedMenu = (key: string) => {
        if (refLastFindSubKey.current === key) {
            return
        }
        refLastFindSubKey.current = key
        const deep = (item: MenuItem) => {
            if (item?.key === key) {
                return true
            }
            if (
                Array.isArray(
                    (item as MenuItem & { children?: MenuItem[] })?.children
                )
            ) {
                return (
                    item as MenuItem & { children: MenuItem[] }
                ).children.some(deep)
            }
        }
        const currentSub = currentItems.find(deep)
        if (currentSub && currentSub.key) {
            setOpenedMenuKeys([currentSub.key as string])
        } else {
            setOpenedMenuKeys(undefined)
        }
    }

    const defaultSelectedKey = useMemo(() => {
        let key
        if (location.pathname === '/') {
            key = 'general'
        } else {
            key =
                (currentItems.find(item =>
                    location.pathname
                        .substring(1)
                        .startsWith(item?.key as string)
                )?.key as string) || location.pathname.substring(1)
        }
        setDefaultOpenedMenu(key)
        return key
    }, [location, currentItems])

    const handleCalculateTop = () => {
        const $el = document.querySelector('.ant-layout-sider-trigger')
        if ($el && refBanner.current) {
            const elOffset = $el.getBoundingClientRect().top - 15
            setTopOffset(
                elOffset - refBanner.current.getBoundingClientRect().height
            )
        }
    }

    useEffect(() => {
        handleCalculateTop()
    }, [location.pathname])

    useEffect(() => {
        if (blocks['blok_adminka_vopros']?.banners[0]) {
            setLeftBanner(blocks['blok_adminka_vopros']?.banners[0])
        }
    }, [blocks])

    useEffect(() => {
        const role = profile ? getRole(profile.roles) : 'user'

        const isRoleAdminOrFranchisee =
            role === 'admin' || role === 'franchisee'

        const profileBlocks = isRoleAdminOrFranchisee
            ? adminFranchiseeBlocks
            : userBlocks

        dispatch(
            dashboardActions.fetchBlocks({
                names: [
                    ...profileBlocks,
                    ...(isRoleAdminOrFranchisee
                        ? adminFranchiseeHorizontalBlocks
                        : []),
                    ...horizontalBlocks,
                    ...verticalBlocks,
                    ...additionalBlocks
                ]
            })
        )
    }, [])

    const breadcrumbs = useMemo(() => {
        const result: { title: string; link: string }[] = []
        let current: typeof breadcrumbsItems = breadcrumbsItems
        const isCompanies = pathnames.includes('companies')
        const isEvents = pathnames.includes('events')

        for (const link of pathnames) {
            const currentItem =
                current[link as keyof typeof current] ?? current.id

            if (currentItem && typeof currentItem.title === 'string') {
                if (
                    current.id &&
                    isCompanies &&
                    (currentCompanyPage || currentCompany)
                ) {
                    result.push({
                        link,
                        title:
                            (currentCompanyPage
                                ? currentCompanyPage.name
                                : currentCompany?.name || current.id.title
                            )?.toString() || 'Название не заполнено'
                    })
                } else if (current.id && isEvents && currentEvent) {
                    result.push({
                        link,
                        title:
                            currentEvent.name ||
                            current.id.title?.toString() ||
                            'Название не заполнено'
                    })
                } else {
                    result.push({ link, title: currentItem.title })
                }

                current = currentItem as IBreadcrumbItem
            }
        }

        let path = ''

        if (pathnames.length === 0) {
            result.push({
                title: 'Главная',
                link: '/'
            })
        }

        return result.map((item, index, arr) => {
            path += `/${item.link}`
            return {
                title:
                    index === arr.length - 1 ? (
                        item.title
                    ) : (
                        <Link to={path}>{item.title}</Link>
                    )
            }
        })
    }, [pathnames, currentCompany, currentCompanyPage])

    const handleClickMenu = ({ key }: { key: string }) => {
        navigate(key === 'general' ? '/' : `/${key}`)
        setDefaultOpenedMenu(key)
    }

    return (
        <AntdLayout
            style={{
                minHeight: '100vh',
                // backdropFilter: 'blur(60px)',
                margin: 15,
                borderRadius: 6
                // overflow: 'hidden'
            }}
        >
            <ScrollToTop />
            <Sider
                ref={refSlider}
                collapsible
                collapsed={collapsed}
                onCollapse={value => {
                    setCollapsed(value)
                    setTimeout(handleCalculateTop, 200)
                }}
                theme={'dark'}
            >
                <Link to={'/'}>
                    <div className={styles.logo} />
                </Link>
                <Menu
                    key={location.pathname}
                    theme={'dark'}
                    defaultOpenKeys={openedMenuKeys}
                    defaultSelectedKeys={[defaultSelectedKey]}
                    mode={'inline'}
                    items={currentItems}
                    onClick={handleClickMenu}
                />
                <div
                    className={`${styles.banner} ${
                        collapsed ? styles.banner__collapsed : ''
                    }`}
                    onClick={event => {
                        event.preventDefault()
                        window.Chatra('updateIntegrationData', {
                            'screen url': window.location.href
                        })
                        window.Chatra('openChat', true)
                    }}
                >
                    <MemoMenuBanner
                        banner={leftBanner}
                        topOffset={topOffset}
                        refBanner={refBanner}
                        onLoad={handleCalculateTop}
                    />
                </div>
            </Sider>
            <AntdLayout>
                <Header style={{ padding: 0, background: colorBgContainer }}>
                    {profile ? (
                        <div className={styles.profile}>
                            <span style={{ color: '#FFF', marginRight: 10 }}>
                                {'Ваш баланс'}
                            </span>
                            {isProcessPaymentSum ? (
                                <Spin
                                    size={'small'}
                                    style={{ marginRight: 15 }}
                                />
                            ) : (
                                <Dropdown
                                    menu={{
                                        items: menuBalanceItems,
                                        onClick: ({ key }) => {
                                            if (key === '1') {
                                                setIsModalAddBalance(true)
                                            }
                                        }
                                    }}
                                >
                                    <Badge
                                        ref={refCurrency}
                                        count={`${
                                            amount
                                                ? Math.round(
                                                      amount / 100
                                                  ).toLocaleString('ru-RU')
                                                : 0
                                        } ₽`}
                                        color={
                                            amount / 100 >= 0
                                                ? '#52c41a'
                                                : 'red'
                                        }
                                        style={{
                                            marginRight: 15,
                                            color: '#FFF',
                                            cursor: 'pointer'
                                        }}
                                        // onClick={() =>
                                        //     navigate('/content/rekl_na_mamado')
                                        // }
                                    />
                                </Dropdown>
                            )}
                            <Avatar
                                src={profile.photo?.thumbnail}
                                shape={'circle'}
                                size={40}
                                icon={<UserOutlined />}
                            />
                            <div className={styles['profile-info']}>
                                <Typography.Title
                                    level={5}
                                    style={{ margin: 0, fontWeight: '400' }}
                                >
                                    {profile.full_name ?? 'Имя не установлено'}
                                </Typography.Title>
                                <Paragraph style={{ margin: 0, fontSize: 12 }}>
                                    {getRoleName(profile.roles)}
                                </Paragraph>
                            </div>
                            <Button
                                className={styles.logout}
                                icon={
                                    <LogoutOutlined style={{ color: '#FFF' }} />
                                }
                                onClick={() => setLogOutModal(true)}
                            />
                        </div>
                    ) : null}
                </Header>
                <Content style={{ margin: '0px 4px 0 16px' }}>
                    {!hideBreadcrumbs ? (
                        <Breadcrumb
                            style={{ margin: '16px 0' }}
                            items={breadcrumbs.filter(Boolean)}
                        />
                    ) : (
                        <div style={{ marginTop: 18 }} />
                    )}
                    {pagesWithoutContainer.includes(location.pathname) ? (
                        <CustomContainer style={{ padding: 0 }}>
                            {children}
                        </CustomContainer>
                    ) : (
                        <CustomContainer
                            style={{
                                padding:
                                    pathnames.length === 0
                                        ? '24px 0 24px 24px'
                                        : 24
                            }}
                        >
                            {children}
                        </CustomContainer>
                    )}
                </Content>
                <Footer style={{ textAlign: 'right' }}>{'ООО "МАМАДО"'}</Footer>
            </AntdLayout>
            <Modal
                title={'Выход'}
                centered
                open={logOutModal}
                width={350}
                onOk={() => dispatch(actions.logOut())}
                okText={'Выйти'}
                cancelText={'Отмена'}
                okButtonProps={{
                    danger: true,
                    loading: isProcessLogOut,
                    className: styles['logout-button'],
                    icon: <LogoutOutlined />
                }}
                onCancel={() => setLogOutModal(false)}
            >
                {'Вы уверены, что хотите выйти?'}
            </Modal>
            <AddBalanceModal
                visible={isModalAddBalance}
                onClose={() => setIsModalAddBalance(false)}
            />
        </AntdLayout>
    )
}
