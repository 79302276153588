import type { CSSProperties, ReactElement } from 'react'
import { Select } from 'antd'
import { useDebounce } from '../../hooks/useDebounce'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from './slice'
import { useAppSelector } from '../../hooks/useAppSelector'
import LoadingSelector from '../../components/LoadingSelector'
import { blogSelectorSelector } from './selector.ts'

interface Props {
    initialValue?: { label: string; value: string }[]
    onChange: (value: { label: string; value: string }[]) => void
    style?: CSSProperties
    placeholder?: string
}

export default function BlogSelector({
    initialValue,
    onChange,
    style,
    placeholder
}: Props): ReactElement {
    const [hasInitialize, setHasInitialize] = useState(false)
    const [preData, setPreData] = useState<{ label: string; value: string }[]>(
        []
    )
    const [search, setSearch] = useState('')
    const debounceSearch = useDebounce<string>(search, 600)

    const dispatch = useDispatch()

    const { data, isFetching } = useAppSelector(blogSelectorSelector)

    useEffect(() => {
        if (!hasInitialize && Array.isArray(initialValue)) {
            setPreData(initialValue)
        }
    }, [initialValue])

    useEffect(() => {
        if (debounceSearch) {
            dispatch(actions.fetch({ title: debounceSearch }))
            setHasInitialize(true)
        } else if (data.length > 0) {
            dispatch(actions.destroy())
        }
    }, [debounceSearch])

    return (
        <Select
            mode={'multiple'}
            showSearch
            allowClear
            value={!hasInitialize ? preData : initialValue}
            style={style}
            options={
                !hasInitialize
                    ? preData
                    : data.map(item => ({
                          label: item.title,
                          value: item.post_id
                      }))
            }
            filterOption={false}
            notFoundContent={isFetching ? <LoadingSelector /> : null}
            loading={isFetching}
            placeholder={placeholder || 'Статья блога'}
            searchValue={search}
            onSearch={setSearch}
            onClear={() => setHasInitialize(true)}
            onChange={(_, options) => {
                if (Array.isArray(options)) {
                    onChange(options)
                }
            }}
        />
    )
}
