import type { ITag } from '../containers/SettingsCompaniesTags/types.ts'

export interface ITagsCompaniesCategory {
    title: string
    categoryId: string
    tags: ITag[]
}

export const tagsCompaniesCategories: ITagsCompaniesCategory[] = [
    {
        title: 'Досуг',
        categoryId: '9d768e06-0508-4cde-b9eb-74a5ad252c90',
        tags: []
    },
    {
        title: 'Еда',
        categoryId: '0e57d6a5-f219-44a7-9836-9042d50e5613',
        tags: []
    },
    {
        title: 'Туризм',
        categoryId: 'afc24ab3-f9e0-4e53-bfb4-5fab9b14c67e',
        tags: []
    },
    {
        title: 'Красота',
        categoryId: '9be8224e-84cf-468f-94b9-e9012f8f77fb',
        tags: []
    },
    {
        title: 'Здоровье',
        categoryId: 'c27a5ede-f90d-4b50-9ca6-cc8bc2332cae',
        tags: []
    },
    {
        title: 'Обучение',
        categoryId: '7a454d58-6bdf-4196-a142-6a0f42f0df73',
        tags: []
    },
    {
        title: 'Спорт',
        categoryId: '88cf2fa0-2daf-43b9-b2ac-8aa083b7733c',
        tags: []
    },
    {
        title: 'Услуги',
        categoryId: '9de9a75c-062b-41c4-a470-2099c8772fc9',
        tags: []
    },
    {
        title: 'Покупки',
        categoryId: 'c63b8508-0861-4946-b401-b14719f02a2d',
        tags: []
    },
    {
        title: 'Онлайн',
        categoryId: '9a90eca6-1995-4c06-84c3-f189c4ef90af',
        tags: []
    }
]
