import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type {
    InitialStateType,
    FetchPayload,
    FetchSuccessPayload,
    FetchFailurePayload,
    CreateTagFailurePayload,
    CreateTagPayload,
    CreateTagSuccessPayload,
    EditTagFailurePayload,
    EditTagPayload,
    EditTagSuccessPayload,
    RemoveTagFailurePayload,
    RemoveTagPayload,
    RemoveTagSuccessPayload
} from './types'
import { tagsCompaniesCategories } from '../../utils/consts.ts'

const initialState: InitialStateType = {
    categories: [],
    isFetching: false,
    isModalTagWizard: false,
    isCreateTagProcess: false,
    isEditTagProcess: false,
    isRemoveTagProcess: false,
    error: null
}

const settingsCompaniesTagsSlice = createSlice({
    name: 'settingsCompaniesTags',
    initialState,
    reducers: {
        fetch(state: InitialStateType, _action: PayloadAction<FetchPayload>) {
            state.isFetching = true
            state.error = null
        },
        fetchSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchSuccessPayload>
        ) {
            state.categories = tagsCompaniesCategories.map(category => ({
                ...category,
                tags: action.payload.tags.filter(
                    tag => tag.company_category_id === category.categoryId
                )
            }))
            state.isFetching = false
        },
        fetchFailure(
            state: InitialStateType,
            action: PayloadAction<FetchFailurePayload>
        ) {
            state.isFetching = false
            state.error = action.payload.error
        },
        toggleTagWizardModal(
            state: InitialStateType,
            action: PayloadAction<boolean>
        ) {
            state.isModalTagWizard = action.payload
        },
        createTag(
            state: InitialStateType,
            _action: PayloadAction<CreateTagPayload>
        ) {
            state.isCreateTagProcess = true
            state.error = null
        },
        createTagSuccess(
            state: InitialStateType,
            _action: PayloadAction<CreateTagSuccessPayload>
        ) {
            state.isModalTagWizard = false
            state.isCreateTagProcess = false
        },
        createTagFailure(
            state: InitialStateType,
            action: PayloadAction<CreateTagFailurePayload>
        ) {
            state.isCreateTagProcess = false
            state.error = action.payload.error
        },
        editTag(
            state: InitialStateType,
            _action: PayloadAction<EditTagPayload>
        ) {
            state.isEditTagProcess = true
            state.error = null
        },
        editTagSuccess(
            state: InitialStateType,
            _action: PayloadAction<EditTagSuccessPayload>
        ) {
            state.isModalTagWizard = false
            state.isEditTagProcess = false
        },
        editTagFailure(
            state: InitialStateType,
            action: PayloadAction<EditTagFailurePayload>
        ) {
            state.isEditTagProcess = false
            state.error = action.payload.error
        },
        removeTag(
            state: InitialStateType,
            _action: PayloadAction<RemoveTagPayload>
        ) {
            state.isRemoveTagProcess = true
            state.error = null
        },
        removeTagSuccess(
            state: InitialStateType,
            _action: PayloadAction<RemoveTagSuccessPayload>
        ) {
            state.isRemoveTagProcess = false
        },
        removeTagFailure(
            state: InitialStateType,
            action: PayloadAction<RemoveTagFailurePayload>
        ) {
            state.isRemoveTagProcess = false
            state.error = action.payload.error
        }
    }
})

export const actions = settingsCompaniesTagsSlice.actions

export default settingsCompaniesTagsSlice.reducer
