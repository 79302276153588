import type { FetchPayload } from './types'
import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import * as api from './api'
import { actions } from './slice'
import { formatApiError, sagaNotificationError } from '../../utils/helpers'

export function* handleFetch(action: { payload: FetchPayload }): SagaIterator {
    try {
        const { data } = yield call(api.fetch, action.payload.title)
        yield put(actions.fetchSuccess({ data: data.data }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchFailure({
                error: message
            })
        )
    }
}

export function* watchFetch(): SagaIterator {
    yield takeEvery(actions.fetch, handleFetch)
}

export default function* watchBlogSelector(): SagaIterator {
    yield all([fork(watchFetch)])
}
