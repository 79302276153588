import { del, get, patch, post } from '../../utils/api.ts'
import type { AxiosResponse } from 'axios'
import type { IMeta } from '../../types'
import type { ITag } from './types.ts'

export const fetchTags = (): Promise<AxiosResponse<IMeta<ITag>>> =>
    get('/admin/event-tags', { page_size: 1000, sort: 'short_id' })

export const createTag = (
    form: Record<string, unknown>
): Promise<AxiosResponse<void>> => post<void>('/admin/event-tags', form)

export const editTag = (
    postTagId: string,
    form: Record<string, unknown>
): Promise<AxiosResponse<ITag>> => patch(`/admin/event-tags/${postTagId}`, form)

export const removeTag = (postTagId: string): Promise<AxiosResponse<void>> =>
    del<void>(`/admin/event-tags/${postTagId}`)
