import { useEffect, useMemo, useState } from 'react'
import {
    Button,
    Col,
    notification,
    Row,
    Space,
    Tabs,
    Tag,
    Typography
} from 'antd'
import { Link, useSearchParams } from 'react-router-dom'
import { useAppSelector } from '../../hooks/useAppSelector'
import { companySelector } from './selectors'
import dayjs from 'dayjs'
import {
    BarChartOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    CloseOutlined,
    CrownOutlined,
    DollarOutlined,
    EditOutlined,
    InfoCircleOutlined,
    SettingOutlined,
    TagsOutlined
} from '@ant-design/icons'
import TabSubscriptions from './components/TabSubscriptions'
import { getRole } from '../../utils/helpers'
import { authorizationSelector } from '../Authorization/selectors'
import { applicationSelector } from '../Application/selectors'
import Status from '../../components/Status'

// Styles
import styles from './styles.module.scss'

// Tabs
import TabGeneral from './components/TabGeneral'
import TabAnalytics from './components/TabAnalytics'
import TabPremium from './components/TabPremium'
import TabGeneralUser from './components/TabGeneralUser'
import TabSettings from './components/TabSettings'
import TabEvents from './components/TabEvents'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'
import TabTags from './components/TabTags'

const { Title, Paragraph } = Typography

const tabs = (onChangeTab: (key: string) => void) => [
    {
        label: (
            <span>
                <InfoCircleOutlined style={{ marginRight: 7 }} />
                {'Общее'}
            </span>
        ),
        key: 'general',
        children: <TabGeneral />,
        roles: ['admin', 'franchisee']
    },
    {
        label: (
            <span>
                <InfoCircleOutlined style={{ marginRight: 7 }} />
                {'Общее'}
            </span>
        ),
        key: 'general',
        children: <TabGeneralUser onChangeTab={onChangeTab} />,
        roles: ['user']
    },
    {
        label: (
            <span>
                <TagsOutlined style={{ marginRight: 7 }} />
                {'Предложения компании'}
            </span>
        ),
        key: 'events',
        children: <TabEvents />,
        roles: ['user', 'admin', 'franchisee']
    },
    {
        label: (
            <span>
                <BarChartOutlined style={{ marginRight: 7 }} />
                {'Аналитический отчет'}
            </span>
        ),
        key: 'analytics',
        children: <TabAnalytics />,
        roles: ['user', 'admin', 'franchisee']
    },
    {
        label: (
            <span>
                <DollarOutlined style={{ marginRight: 7 }} />
                {'Подписка'}
            </span>
        ),
        key: 'subscription',
        children: <TabSubscriptions />,
        roles: ['admin', 'franchisee']
    },
    {
        label: (
            <span>
                <CrownOutlined style={{ marginRight: 7 }} />
                {'Оплата "Премиум статуса"'}
            </span>
        ),
        key: 'premium',
        children: <TabPremium />,
        roles: ['user']
    },
    {
        label: (
            <span>
                <TagsOutlined style={{ marginRight: 7 }} />
                {'Теги категории'}
            </span>
        ),
        key: 'tags',
        children: <TabTags />,
        roles: ['admin', 'franchisee']
    },
    {
        label: (
            <span>
                <SettingOutlined style={{ marginRight: 7 }} />
                {'Настройка лендинга'}
            </span>
        ),
        key: 'settings',
        children: <TabSettings />,
        roles: ['admin', 'franchisee', 'user']
    }
]

// function ReferralLink({
//     dynamic_code,
//     loading
// }: {
//     dynamic_code?: string
//     loading: boolean
// }) {
//     if (loading) {
//         return <Spin size={'small'} style={{ marginRight: 10 }} />
//     }
//
//     if (dynamic_code) {
//         return (
//             <Row>
//                 <Paragraph style={{ marginRight: 5 }} type={'secondary'}>
//                     {'Реферальная ссылка:'}
//                 </Paragraph>
//                 <Paragraph copyable>
//                     {`https://r1.mamado.su/${dynamic_code}`}
//                 </Paragraph>
//             </Row>
//         )
//     }
//
//     return (
//         <Paragraph type={'secondary'}>
//             {'У компании еще нет приглашения'}
//         </Paragraph>
//     )
// }

export default function Company() {
    const [activeKey, setActiveKey] = useState('')

    const { currentCompany } = useAppSelector(companySelector)
    const { profile } = useAppSelector(authorizationSelector)
    const { plans } = useAppSelector(applicationSelector)

    const [searchParams, setSearchParams] = useSearchParams()

    const [api, contextHolder] = notification.useNotification()

    const profileRole = profile ? getRole(profile.roles) : undefined

    const isAdminOrFranchisee =
        profileRole === 'admin' || profileRole === 'franchisee'

    const dispatch = useDispatch()

    useEffect(() => {
        const tab = searchParams.get('tab')
        setActiveKey(tab || 'general')
    }, [searchParams])

    useEffect(() => {
        if (!isAdminOrFranchisee && currentCompany?.admin_message) {
            api.open({
                type: 'warning',
                message: 'Сообщение от администратора',
                description: currentCompany.admin_message,
                placement: 'bottomRight',
                duration: null,
                btn: (
                    <Button
                        size={'small'}
                        type={'primary'}
                        onClick={() => {
                            if (currentCompany) {
                                api.destroy()
                                dispatch(
                                    actions.clearAdminMessage({
                                        company_id: currentCompany.company_id
                                    })
                                )
                            }
                        }}
                    >
                        {'Принято'}
                    </Button>
                )
            })
        }
        return () => {
            api.destroy()
        }
    }, [currentCompany])

    const currentPlan = useMemo(() => {
        if (
            currentCompany?.activePlanSubscription?.is_active &&
            plans.length > 0
        ) {
            return plans
                .filter(
                    item =>
                        item.planId ===
                        currentCompany.activePlanSubscription!.planId
                )
                .map(item => ({
                    name: item.name.title,
                    endsAt: dayjs(
                        currentCompany.activePlanSubscription!.endsAt
                    ).format('DD.MM.YYYY в HH:mm')
                }))[0]
        }
        return null
    }, [currentCompany, plans])

    const handleChangeTab = (key: string) => {
        setActiveKey(key)
        setSearchParams({ tab: key })
    }

    if (!currentCompany) {
        return <></>
    }

    return (
        <>
            <Row justify={'space-between'} align={'middle'}>
                <Row align={currentPlan ? 'middle' : 'top'}>
                    <Col style={{ marginRight: 10 }}>
                        <Title
                            className={styles.title}
                            title={currentCompany.name}
                            level={3}
                        >
                            {currentCompany.name}
                        </Title>
                        <Status
                            type={currentCompany.type}
                            published={!!currentCompany.cached_displayed}
                        />
                    </Col>
                    {profileRole !== 'user' ? (
                        <>
                            {currentPlan ? (
                                <Paragraph
                                    type={'secondary'}
                                    style={{ fontSize: 14 }}
                                >
                                    <>
                                        <Row
                                            align={'middle'}
                                            style={{
                                                color: 'green',
                                                fontSize: 14
                                            }}
                                        >
                                            <ClockCircleOutlined
                                                style={{
                                                    marginRight: 5
                                                }}
                                            />{' '}
                                            {'Статус «Премиум» активен'}
                                        </Row>
                                        {'Истекает: '}
                                        {currentPlan.endsAt}
                                    </>
                                </Paragraph>
                            ) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <Row
                                        align={'middle'}
                                        style={{
                                            color: 'red',
                                            fontSize: 14,
                                            marginTop: 13
                                        }}
                                    >
                                        <CloseCircleOutlined
                                            style={{
                                                marginRight: 5
                                            }}
                                        />
                                        {'Нет активной подписки'}
                                    </Row>
                                    <Button
                                        icon={<CrownOutlined />}
                                        onClick={() =>
                                            handleChangeTab('subscription')
                                        }
                                        style={{ marginTop: 5 }}
                                        size={'small'}
                                    >
                                        {'Подключить'}
                                    </Button>
                                </div>
                            )}
                        </>
                    ) : null}
                </Row>
                <Row style={{ flex: 1 }} gutter={8} wrap={true} justify={'end'}>
                    {Array.isArray(currentCompany?.summary_status) &&
                    currentCompany.summary_status.length > 0 ? (
                        <Col
                            span={24}
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginBottom: 10,
                                marginRight: -6
                            }}
                        >
                            <Space size={[0, 'small']} wrap>
                                {currentCompany.summary_status.map(
                                    (status, index) => (
                                        <Tag
                                            key={`summary-${index}`}
                                            bordered={false}
                                            color={'error'}
                                        >
                                            {status}
                                        </Tag>
                                    )
                                )}
                            </Space>
                        </Col>
                    ) : null}
                    <Col>
                        <Link to={'edit'}>
                            <Button type={'primary'} icon={<EditOutlined />}>
                                {'Редактировать компанию'}
                            </Button>
                        </Link>
                    </Col>
                    <Col>
                        <Link to={'/companies'}>
                            <Button icon={<CloseOutlined />}>
                                {'Закрыть'}
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </Row>
            <Tabs
                activeKey={activeKey || 'general'}
                defaultActiveKey={'general'}
                onChange={activeKey =>
                    setSearchParams(
                        activeKey !== 'general'
                            ? {
                                  tab: activeKey
                              }
                            : {}
                    )
                }
                items={tabs(handleChangeTab).filter(item =>
                    profileRole ? item.roles.includes(profileRole) : false
                )}
                tabBarStyle={{ borderBottom: '1px solid rgb(254 198 227)' }}
            />
            {contextHolder}
        </>
    )
}
