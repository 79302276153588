import type { AxiosResponse } from 'axios'
import { get } from '../../utils/api'
import type { IPost } from '../Blog/types.ts'
import type { IMeta } from '../../types'

export const fetch = (title: string): Promise<AxiosResponse<IMeta<IPost>>> =>
    get<IMeta<IPost>>('/admin/blog/posts', {
        page_size: 10,
        'filter[title]': title
    })
