import { useEffect, useMemo, useState } from 'react'
import type { EditorConfig } from '@editorjs/editorjs'
import type EditorJS from '@editorjs/editorjs'
import Header from '@editorjs/header'
import List from '@editorjs/list'
import Table from '@editorjs/table'
import ColorPlugin from 'editorjs-text-color-plugin'
import LinkTool from '@editorjs/link'
import Delimiter from '@editorjs/delimiter'
import Warning from '@editorjs/warning'
import Checklist from '@editorjs/checklist'
import AttachesTool from '@editorjs/attaches'
import Embed from '@editorjs/embed'
import DragDrop from 'editorjs-drag-drop'
import {
    uploadFilePDF,
    uploadImageBanner
} from '../containers/AdvertisingWizard/api.ts'
import * as Sentry from '@sentry/react'
import { useAppSelector } from './useAppSelector.ts'
import { filesize } from 'filesize'
import { authorizationSelector } from '../containers/Authorization/selectors.ts'

interface Props {
    settings: EditorConfig
    dep?: number
    ignoreTools?: 'companiesSelector'[]
}

export const useEditorJS = (options: Props) => {
    const [editorInstance, setEditorInstance] = useState<EditorJS | null>(null)

    const { token } = useAppSelector(authorizationSelector)

    const mainTools = useMemo(() => {
        const tools = {
            paragraph: {
                class: window.Paragraph,
                inlineToolbar: true,
                config: {
                    preserveBlank: true
                }
            },
            header: {
                class: Header,
                inlineToolbar: true,
                config: {
                    placeholder: 'Начните вводить заголовок',
                    levels: [2, 3, 4, 5],
                    defaultLevel: 2
                }
            },
            list: List,
            image: {
                class: window.ImageTool,
                config: {
                    buttonContent: 'Добавить изображение',
                    captionPlaceholder: 'Описание изображения',
                    uploader: {
                        uploadByFile: async (file: File) => {
                            try {
                                const { data } = await uploadImageBanner(file)
                                return {
                                    success: 1,
                                    file: {
                                        url: data.url
                                    }
                                }
                            } catch (error) {
                                Sentry.captureException(error)
                            }
                        }
                    }
                }
            },
            gallery: {
                class: window.ImageGallery,
                config: {
                    maxElementCount: 10,
                    buttonContent: 'Добавить изображение',
                    captionPlaceholder: 'Описание изображения',
                    uploader: {
                        uploadByFile: async (file: File) => {
                            try {
                                const { data } = await uploadImageBanner(file)
                                return {
                                    success: 1,
                                    file: {
                                        url: data.url
                                    }
                                }
                            } catch (error) {
                                Sentry.captureException(error)
                            }
                        }
                    }
                }
            },
            table: {
                class: Table,
                inlineToolbar: true
            },
            toggle: {
                class: window.ToggleBlock,
                inlineToolbar: true,
                config: {
                    placeholder: 'Заголовок переключателя',
                    defaultContent:
                        'Содержимое после разворачивания переключателя'
                }
            },
            marker: {
                class: ColorPlugin,
                config: {
                    defaultColor: 'transparent',
                    colorCollections: [
                        'transparent',
                        '#fceaf7',
                        '#fbeced',
                        '#eaf2fd',
                        '#dfeddb'
                    ],
                    type: 'marker',
                    icon: '<svg fill="#000000" height="200px" width="200px" version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M17.6,6L6.9,16.7c-0.2,0.2-0.3,0.4-0.3,0.6L6,23.9c0,0.3,0.1,0.6,0.3,0.8C6.5,24.9,6.7,25,7,25c0,0,0.1,0,0.1,0l6.6-0.6 c0.2,0,0.5-0.1,0.6-0.3L25,13.4L17.6,6z"></path> <path d="M26.4,12l1.4-1.4c1.2-1.2,1.1-3.1-0.1-4.3l-3-3c-0.6-0.6-1.3-0.9-2.2-0.9c-0.8,0-1.6,0.3-2.2,0.9L19,4.6L26.4,12z"></path> </g> <g> <path d="M28,29H4c-0.6,0-1-0.4-1-1s0.4-1,1-1h24c0.6,0,1,0.4,1,1S28.6,29,28,29z"></path> </g> </g></svg>'
                }
            },
            clearFormatting: {
                class: window.Formatting,
                inlineToolbar: true
            },
            linkTool: {
                class: LinkTool,
                config: {
                    endpoint: 'https://opengraph-app.mamado.su/fetchUrl'
                }
            },
            delimiter: Delimiter,
            warning: {
                class: Warning,
                inlineToolbar: true,
                config: {
                    titlePlaceholder: 'Заголовок',
                    messagePlaceholder: 'Сообщение'
                }
            },
            checklist: {
                class: Checklist,
                inlineToolbar: true
            },
            alert: {
                class: window.Alert,
                config: {
                    messagePlaceholder: 'Начните вводить текст',
                    alertTypes: ['primary', 'secondary', 'info', 'success']
                }
            },
            attaches: {
                class: AttachesTool,
                config: {
                    types: 'application/pdf',
                    buttonText: 'Добавить pdf файл',
                    errorMessage: 'Ошибка загрузки файла',
                    uploader: {
                        uploadByFile: async (file: File) => {
                            try {
                                const regFileName = /.*\/(.*)/
                                const { data } = await uploadFilePDF(file)
                                const name = data.original.match(regFileName)
                                return {
                                    success: 1,
                                    file: {
                                        url: data.original,
                                        title: name ? name[1] : undefined,
                                        size: file.size,
                                        customSize: filesize(file.size, {
                                            standard: 'jedec'
                                        })
                                    }
                                }
                            } catch (error) {
                                Sentry.captureException(error)
                            }
                        }
                    }
                }
            },
            embed: {
                class: Embed,
                config: {
                    services: {
                        youtube: true,
                        'yandex-music-track': true,
                        'yandex-music-album': true,
                        'yandex-music-playlist': true,
                        'vk-video': {
                            regex: /https?:\/\/vk.com\/video-(\d{1,20}_\d{1,20}$)/,
                            embedUrl:
                                'https://vk.com/video_ext.php?<%= remote_id %>',
                            html: "<iframe height='360' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='width: 100%;'></iframe>",
                            height: 360,
                            width: 640,
                            id: (groups: [string]) => {
                                if (Array.isArray(groups)) {
                                    const [oid, id] = groups[0].split('_')
                                    return `oid=-${oid}&id=${id}`
                                }
                            }
                        }
                    }
                }
            },
            AnyButton: {
                class: window.AnyButton,
                inlineToolbar: false,
                config: {
                    css: {
                        btnColor: 'btn--gray'
                    }
                }
            },
            companiesSelector: {
                class: window.CompaniesSelector,
                config: {
                    generalSite: process.env.SITE_URL,
                    host: process.env.API_URL,
                    token
                }
            },
            colorBlock: {
                class: window.ColorBlock,
                inlineToolbar: true
            }
        }

        if (options.ignoreTools) {
            for (const tool of options.ignoreTools) {
                delete tools[tool as keyof typeof tools]
            }
        }

        return tools
    }, [options])

    useEffect(() => {
        const editor = new window.EditorJS({
            placeholder: 'Нажмите для ввода текста',
            tools: {
                ...mainTools,
                columns: {
                    class: window.editorjsColumns,
                    config: {
                        EditorJsLibrary: window.EditorJS,
                        tools: mainTools
                    }
                }
            },
            i18n: {
                messages: {
                    tools: {
                        header: {
                            'Heading 2': 'Заголовок 2',
                            'Heading 3': 'Заголовок 3',
                            'Heading 4': 'Заголовок 4',
                            'Heading 5': 'Заголовок 5'
                        },
                        list: {
                            Ordered: 'Нумерованный',
                            Unordered: 'Маркированный'
                        },
                        link: {
                            'Add a link': 'Вставьте ссылку'
                        },
                        AnyButton: {
                            'Button Text': 'Название кнопки',
                            'Link Url': 'Ссылка',
                            Set: 'Применить',
                            'Default Button': 'Кнопка по умолчанию'
                        },
                        table: {
                            'Add column to left': 'Добавить колонку слева',
                            'Add column to right': 'Добавить колонку справа',
                            'Delete column': 'Удалить колонку',
                            'Add row above': 'Добавить строку выше',
                            'Add row below': 'Добавить строку ниже',
                            'Delete row': 'Удалить строку',
                            Heading: 'Заголовок',
                            'With headings': 'С заголовками',
                            'Without headings': 'Без заголовков'
                        },
                        linkTool: {
                            Link: 'Введите ссылку'
                        },
                        alert: {
                            Primary: 'Розовый',
                            Secondary: 'Оранжевый',
                            Info: 'Голубой',
                            Success: 'Зеленый',
                            Left: 'Влево',
                            Center: 'По центру',
                            Right: 'Вправо'
                        }
                    },
                    ui: {
                        blockTunes: {
                            toggler: {
                                'Click to tune': 'Нажмите, чтобы настроить',
                                'or drag to move': 'или перетащите'
                            }
                        },
                        inlineToolbar: {
                            converter: {
                                'Convert to': 'Конвертировать в'
                            }
                        },
                        toolbar: {
                            toolbox: {
                                Add: 'Добавить'
                            }
                        },
                        popover: {
                            Filter: 'Поиск',
                            'Nothing found': 'Ничего не найдено'
                        }
                    },
                    toolNames: {
                        Text: 'Параграф',
                        Heading: 'Заголовок',
                        List: 'Список',
                        Image: 'Изображение',
                        Warning: 'Примечание',
                        Checklist: 'Чеклист',
                        Code: 'Код',
                        Delimiter: 'Разделитель',
                        'Raw HTML': 'HTML-фрагмент',
                        Table: 'Таблица',
                        Link: 'Ссылка',
                        Marker: 'Маркер',
                        Color: 'Цвет',
                        Bold: 'Полужирный',
                        Italic: 'Курсив',
                        InlineCode: 'Моноширинный',
                        Toggle: 'Список - аккордеон',
                        Columns: 'Разделение',
                        Alert: 'Внимание',
                        Attachment: 'PDF Файл',
                        Button: 'Кнопка с ссылкой',
                        Gallery: 'Карусель',
                        ClearFormatting: 'Очистить форматирование'
                    },
                    blockTunes: {
                        delete: {
                            Delete: 'Удалить',
                            'Click to delete': 'Удалить'
                        },
                        moveUp: {
                            'Move up': 'Переместить вверх'
                        },
                        moveDown: {
                            'Move down': 'Переместить вниз'
                        },
                        'With border': 'С бордером'
                    }
                }
            },
            onReady: () => {
                setTimeout(() => new DragDrop(editor), 200)
            },
            ...options.settings
        })

        setEditorInstance(editor)

        return () => {
            const $elem = document.querySelector(`#${options.settings.holder}`)
            if ($elem) {
                $elem.innerHTML = ''
            }
        }
    }, [options.dep])

    return editorInstance
}
