import type { ReactElement } from 'react'
import { useState } from 'react'
import { useEffect, useMemo } from 'react'
import { Flex, Popconfirm, Row, Tabs, Tag, theme, Typography } from 'antd'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { settingsCompaniesTagsSelector } from './selectors.ts'
import { LoadingStep } from '../../components/LoadingStep'
import { actions } from './slice.ts'
import { useDispatch } from 'react-redux'
import { CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import CompanyTagWizardModal from '../../components/CompanyTagWizardModal'
import type { ITag } from './types.ts'

const { Title } = Typography

export default function SettingsCompaniesTags(): ReactElement {
    const [tab, setTab] = useState('0')
    const [selectedTag, setSelectedTag] = useState<ITag | undefined>()

    const { token } = theme.useToken()

    const { categories, isFetching } = useAppSelector(
        settingsCompaniesTagsSelector
    )

    const dispatch = useDispatch()

    const selectedCategory = categories.find(
        (_, index) => index.toString() === tab
    )?.categoryId

    useEffect(() => {
        dispatch(actions.fetch())
    }, [])

    const items = useMemo(
        () =>
            categories.map((category, index) => ({
                key: index.toString(),
                label: category.title,
                post_category_id: category.categoryId,
                children: (
                    <Flex gap={'4px 0'} wrap={'wrap'}>
                        {category.tags.map(tag => (
                            <Tag
                                key={tag.company_tag_id}
                                style={{
                                    background: 'rgb(255, 87, 166)',
                                    border: 'none',
                                    color: '#FFF'
                                }}
                                onClose={e => {
                                    e.preventDefault()
                                }}
                            >
                                {tag.title}
                                <EditOutlined
                                    onClick={() => {
                                        setSelectedTag(tag)
                                        dispatch(
                                            actions.toggleTagWizardModal(true)
                                        )
                                    }}
                                    style={{
                                        marginLeft: 5,
                                        cursor: 'pointer',
                                        outline: 0
                                    }}
                                />
                                <Popconfirm
                                    placement={'topLeft'}
                                    title={
                                        'Вы уверены, что хотите удалить тег?'
                                    }
                                    description={
                                        'Важна последовательность тегов, если удалить, некоторые страницы будут отдавать 404'
                                    }
                                    okText={'Да'}
                                    cancelText={'Нет'}
                                    onConfirm={() =>
                                        dispatch(
                                            actions.removeTag({
                                                post_tag_id: tag.company_tag_id
                                            })
                                        )
                                    }
                                >
                                    <CloseOutlined style={{ color: '#FFF' }} />
                                </Popconfirm>
                            </Tag>
                        ))}
                        <Tag
                            style={{
                                height: 22,
                                background: token.colorBgContainer,
                                borderStyle: 'dashed',
                                cursor: 'pointer'
                            }}
                            icon={<PlusOutlined />}
                            onClick={() =>
                                dispatch(actions.toggleTagWizardModal(true))
                            }
                        >
                            {'Новый тег'}
                        </Tag>
                    </Flex>
                )
            })),
        [categories]
    )

    return (
        <>
            <Row justify={'space-between'}>
                <Title level={3} style={{ margin: 0 }}>
                    {'Теги компаний'}
                </Title>
            </Row>
            {categories.length === 0 && isFetching ? (
                <LoadingStep />
            ) : (
                <Tabs activeKey={tab} items={items} onChange={setTab} />
            )}
            <CompanyTagWizardModal
                selectedTag={selectedTag}
                postCategoryId={selectedCategory ?? ''}
                onAfterClose={() => {
                    setSelectedTag(undefined)
                }}
            />
        </>
    )
}
