import type { IForm, IPost, IImage } from './types.ts'
import type { AxiosResponse } from 'axios'
import { get, post, upload } from '../../utils/api.ts'

export const create = (form: IForm): Promise<AxiosResponse<IPost>> =>
    post('/admin/blog/posts', { ...form })

export const edit = (
    postId: string,
    form: IForm | Record<string, unknown>
): Promise<AxiosResponse<IPost>> =>
    post(`/admin/blog/posts/${postId}`, { ...form })

export const fetch = (postId: string): Promise<AxiosResponse<IPost>> =>
    get(`/admin/blog/posts/${postId}`, {
        include: [
            'image',
            'user',
            'franchisee',
            'company',
            'categories',
            'areas',
            'postTags',
            'relevantPosts'
        ]
    })

export const uploadCover = (image: File): Promise<AxiosResponse<IImage>> =>
    upload<IImage>(
        '/files',
        [
            {
                field: 'file',
                file: image
            }
        ],
        undefined,
        undefined,
        true
    )
