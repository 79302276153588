import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import * as api from './api'
import { actions } from './slice'
import { formatApiError, sagaNotificationError } from '../../utils/helpers'
import type { PayloadAction } from '@reduxjs/toolkit'
import type {
    CreateTagPayload,
    EditTagPayload,
    RemoveTagPayload
} from './types.ts'

export function* handleFetch(): SagaIterator {
    try {
        const { data } = yield call(api.fetchTags)
        yield put(actions.fetchSuccess({ tags: data.data }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchFailure({
                error: message
            })
        )
    }
}

export function* watchFetch(): SagaIterator {
    yield takeEvery(actions.fetch, handleFetch)
}

export function* handleCreateTag(
    action: PayloadAction<CreateTagPayload>
): SagaIterator {
    try {
        yield call(api.createTag, action.payload.form)
        yield put(actions.createTagSuccess())
        yield put(actions.fetch())
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.createTagFailure({
                error: message
            })
        )
    }
}

export function* watchFetchCreateTag(): SagaIterator {
    yield takeEvery(actions.createTag, handleCreateTag)
}

export function* handleEditTag(
    action: PayloadAction<EditTagPayload>
): SagaIterator {
    try {
        yield call(
            api.editTag,
            action.payload.company_tag_id,
            action.payload.form
        )
        yield put(actions.editTagSuccess())
        yield put(actions.fetch())
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.editTagFailure({
                error: message
            })
        )
    }
}

export function* watchFetchEditTag(): SagaIterator {
    yield takeEvery(actions.editTag, handleEditTag)
}

export function* handleRemoveTag(
    action: PayloadAction<RemoveTagPayload>
): SagaIterator {
    try {
        yield call(api.removeTag, action.payload.post_tag_id)
        yield put(actions.removeTagSuccess())
        yield put(actions.fetch())
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.removeTagFailure({
                error: message
            })
        )
    }
}

export function* watchFetchRemoveTag(): SagaIterator {
    yield takeEvery(actions.removeTag, handleRemoveTag)
}

export default function* watchSettingsCompaniesTags(): SagaIterator {
    yield all([
        fork(watchFetch),
        fork(watchFetchCreateTag),
        fork(watchFetchEditTag),
        fork(watchFetchRemoveTag)
    ])
}
